<template>
    <v-expand-transition>
        <v-card
            max-width="400"
            prepend-icon="mdi-card-account-mail-outline"
            :title="$t('landingPage.bottomBar.contactInfoCard.title')"
            v-show="getContactDialogOpen"
            class="position-absolute bottom-0 right-0 mb-28 mr-4">
            <template v-slot:append>
                <v-btn
                    icon="mdi-close"
                    @click="toggleContactDialogOpen()"></v-btn>
            </template>
            <template v-slot:text>
                <p>{{ $t("landingPage.bottomBar.contactInfoCard.phone") }}</p>
                <p>{{ $t("landingPage.bottomBar.contactInfoCard.email") }}</p>
                <p>
                    {{ $t("landingPage.bottomBar.contactInfoCard.address") }}
                </p>
                <p>
                    {{ $t("landingPage.bottomBar.contactInfoCard.CIN") }}
                </p>
            </template>
        </v-card>
    </v-expand-transition>
</template>
<script>
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";

// Components
export default defineComponent({
    name: "BottomBarContactDialog",

    components: {},
    methods: {
        ...mapActions("LandingPage", ["toggleContactDialogOpen"]),
    },
    computed: {
        ...mapGetters("LandingPage", {
            getContactDialogOpen: "getContactDialogOpen",
        }),
    },
});
</script>
<style scoped>
.mb-28 {
    margin-bottom: 112px;
}
</style>
