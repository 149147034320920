import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { createVuetify } from "vuetify";
import { loadFonts } from "./plugins/webfontloader";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import { createVueI18nAdapter } from "vuetify/locale/adapters/vue-i18n";
import { createI18n, useI18n } from "vue-i18n";
import { en, pl } from "vuetify/locale";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "vuetify/styles";
import VueGtag from "vue-gtag";
import VueCookies from "vue-cookies";

// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
loadFonts();

const messages = {
    en: {
        $vuetify: {
            ...en,
        },
        landingPage: {
            component1: {
                menu: {
                    learnMore: "Learn more",
                    photos: "Photos",
                },
                card: {
                    title: "Visit Casa Di Dario",
                    text: "Book your visit in beautiful Italian house in Umbria region. Experience beautiful countryside and amazing wines yourself.",
                    button: "Inqiure about the price",
                },
                contactForm: {
                    title: "Inqiure about the price and available dates.",
                    subtitle:
                        "You can send your inquiry to us using the form below. We will get back to you shortly via email.",
                    firstName: "First name*",
                    lastName: "Last name*",
                    email: "Email*",
                    nOfPeople: "Number of people*",
                    startingDate: "Select starting date*",
                    endingDate: "Select ending date*",
                    additionalInfo: "Additional information or requests",
                    indicates: "*indicates required field",
                    close: "Close",
                    send: "Send",
                    problem:
                        "{'There was a problem with sending your request. Contact us using e-mail: Dario@casadidario.it'}",
                    success:
                        "Your request was sent. We will contact you through provided e-mail address.",
                },
            },
            component2: {
                learnMore: "Learn more about La Casa Di Dario",
                tabSelector: {
                    house: "The House",
                    location: "Location",
                    accomodation: "Accomodation",
                },
                tabText: {
                    house: {
                        p1: 'Casa "Di Dario" is a unique rental home situated on an 8,000 square meter plot in Umbria, on the border with Tuscany.',
                        p2: "The ground floor of the house includes a spacious 90-square-meter living room, furnished in rustic Umbrian style, as well as a modern, fully equipped kitchen and a bathroom. Upstairs, there are four comfortable bedrooms and two bathrooms.",
                        p3: "The house is air-conditioned and powered by electricity from installed solar panels.",
                        p4: "Additionally, the property features a 12 x 6 meter swimming pool, a large recreational area, and a small olive grove.",
                    },
                    location: {
                        p1: 'Casa "Di Dario" is nestled among beautiful vineyards and olive groves, just 4 kilometers from the charming town of Montecchio. The breathtaking views of the valley, surrounding hills, and the town itself are complemented by a distant view of the highest peak in Tuscany, Monte Amiata.',
                        p2: "The nearest historic attractions are the towns of Orvieto, located 20 kilometers away, and Todi, 25 kilometers away. Its convenient location means that Rome is only 110 kilometers away, and the nearest train stations in Alviano and Orvieto can be reached from Rome in just an hour and fifteen minutes by train.",
                        p3: 'Within an hour\'s drive from Casa "Di Dario" are picturesque, historic towns such as Perugia, Montepulciano, Cortona, Narni, Amelia, Bagnoreggio, Spoleto, and many others.',
                    },
                    accomodation: {
                        p1: 'Casa "Di Dario" offers accommodation for up to 8 people in four bedrooms, three of which are equipped with large double beds, and the last one with two single beds.',
                        p2: "The minimum stay is 1 week. The house welcomes guests from May to October, details concerning reservations for the rest of the year can be discussed through email.",
                    },
                },
            },
            component3: {
                discover: "Discover the property in photos",
                tabSelector: {
                    outside: "Outside",
                    downstairs: "Downstairs",
                    upstairs: "Upstairs",
                    surroundings: "Surroundings",
                },
            },
            contactInfoButton: {
                seeContactInfo: "See contact info",
                contactInfoCard: {
                    title: "Contact info",
                    phone: "Phone: +48 603 110 010",
                    email: "{'E-mail: Dario@casadidario.it'}",
                    address:
                        "Address: Via Volara 8, Montecchio (Province Terni), Umbria, Italy 05020",
                    CIN: "CIN: IT055018C2L6034847",
                },
            },
            bottomBar: {
                copyright: "Copyright © 2024 Casa Di Dario",
                downloadRules: "Download rules and regulations",
                seeContactInfo: "See contact info",
                contactInfoCard: {
                    title: "Contact info",
                    phone: "Phone: +48 603 110 010",
                    email: "{'E-mail: Dario@casadidario.it'}",
                    address:
                        "Address: Via Volara 8, Montecchio (Province Terni), Umbria, Italy 05020",
                    CIN: "CIN: IT055018C2L6034847",
                },
            },
            privacyPolicy: {
                button: "Privacy policy",
                title: "PRIVACY POLICY",
                p1: "From May 25, 2018 Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of legal persons with regard to the issuance of personal data in a legal matter that is subject to such data, the so-called GDPR.",
                p2: "The application regulations are sent to you about the processing of personal data by our company.",
                list1: {
                    li1: "The administrator of your data is Casa di Dario, Dariusz Węglicki, ul. Pocztowa 5H, 05-520 Konstancin-Jeziorna, Poland NIP: 5211549145 and Daweg Consulting sp. z o. o., ul. Koncertowa 11 lok. 3, 02-787 Warszawa, NIP: 5213276556,",
                    li2: "In order to protect personal data, the administrator operates based on internal procedures and regulations, provisions contained in regulatory acts regarding the protection of data subjects.",
                    li3: {
                        p1: "Your personal data are gathered for the purpose of:",
                        li1: "making a reservation (name and surname, e-mail address, telephone number are required),",
                        li2: "providing answers to questions related to services,",
                        li3: "transaction execution,",
                        li4: "introduction or performance of a contract, the entry of which is the data subject, or which enable action on the data subject before entering the contract, sending information by means of electronic communication, pre-devices or products of the Administrator's services,",
                        li5: "provision of all residential services in Casa di Dario",
                        li6: "business activity investigation,",
                        li7: "fulfillment of the Administrator's obligation,",
                        li8: "making services available for regulatory purposes,",
                        li9: "safety and security,",
                        li10: "sending the application and marketing information in the event of a request to receive marketing data,",
                        li11: "providing personal data is necessary to provide the service, check in at the hotel, provide residential services on the hotel premises and answer questions regarding residential services by the Administrator.",
                    },
                },
                p3: "Failure to provide data will render impossible to check-in at the facility and provide other services offered by Casa di Dario.",
                p4: "Your personal data may be transferred to companies with which we cooperate",
                list2: {
                    li1: "law firms to establish, pursue or defend against claims",
                    li2: "IT companies providing software and IT services",
                    li3: "a security company to ensure security",
                    li4: "Your data will be stored for the period necessary to perform the contract or pursue claims related to the business activity, the period necessary under the law, and in the case of personal data processed on the basis of the consent of the data subject - until the consent is withdrawn.",
                },
                list3: {
                    li4: "Your data will not be transferred to a third country",
                    li5: {
                        p1: "We would like to inform you that you have the right to:",
                        li1: "access to your personal data, rectification, deletion, restriction of processing and transfer,",
                        li2: "submit a complaint to the supervisory authority,",
                        li3: "withdraw consent to the processing of personal data at any time. Withdrawal of consent does not affect the lawfulness of processing based on consent before its withdrawal. Withdrawal of consent may be made in the same form in which the consent was granted,",
                        li4: "object to the processing of your data if the Administrator intends to process it for marketing purposes or to transfer your personal data to another data administrator,",
                        li5: "obtaining information regarding the presence of your data in the Administrator's files, the purpose, scope and method of processing the data contained in such a file. The status from when your data is processed in the file. Source of data acquisition. Sharing your data, in particular information about the recipients or categories of recipients to whom the data is shared.",
                    },
                },
            },
            cookiesSheet: {
                title: "Your privacy",
                p1: "This business determines the use of personal data collectied on our media properties and across the internet. We may collect data that you submit to us directly or data that we collect automatically including from cookies (such as device information or IP address).",
                p21: "Please read our ",
                p2pp: "Privacy Policy",
                p22: "to learn about our privacy practices.",
                accept: "Accept",
                decline: "Decline",
            },
        },
    },
    pl: {
        $vuetify: {
            ...pl,
        },
        landingPage: {
            component1: {
                menu: {
                    learnMore: "Dowiedz się więcej",
                    photos: "Zdjęcia",
                },
                card: {
                    title: "Odwiedź Casa Di Dario",
                    text: "Zarezerwuj swoją wizytę w pięknym włoskim domu w regionie Umbria. Samemu odkryj piękny krajobraz i spróbuj przepysznego wina.",
                    button: "Zapytaj się o cenę",
                },
                contactForm: {
                    title: "Zapytaj o cenę i dostępne terminy",
                    subtitle:
                        "Możesz wysłać swoje zapytanie do nas przy użyciu poniższego formularzu. Odpowiemy jak najszybciej przy użyciu emaila.",
                    firstName: "Imię*",
                    lastName: "Nazwisko*",
                    email: "Email*",
                    nOfPeople: "Liczba osób*",
                    startingDate: "Wybierz datę początkową*",
                    endingDate: "Wybierz datę końcową*",
                    additionalInfo: "Dodatkowe informację lub prośby",
                    indicates: "*to pole jest wymagane",
                    close: "Zamknij",
                    send: "Wyślij",
                    problem:
                        "Wystąpił problem z wysłaniem zapytania. Skontaktuj się z nami przez e-mail: .",
                    success:
                        "Twoje zapytanie zostało wysłane. Odezwiemy się do Ciebie na podany adres e-mail.",
                },
            },
            component2: {
                learnMore: "Dowiedz się więcej o Casa Di Dario",
                tabSelector: {
                    house: "Dom",
                    location: "Lokalizacja",
                    accomodation: "Zakwaterowanie",
                },
                tabText: {
                    house: {
                        p1: "Casa „Di Dario” to unikatowy dom na wynajem, położony na działce o powierzchni 8 tysięcy metrów kwadratowych w Umbrii, na pograniczu z Toskanią.",
                        p2: "Parter domu obejmuje przestronny 90-cio metrowy salon, umeblowany w rustykalnym umbryjskim stylu oraz nowoczesną w pełni wyposażoną kuchnię i łazienkę. Na piętrze znajdują się 4 wygodne sypialnie i dwie łazienki.",
                        p3: "Dom jest klimatyzowany i zasilany w energię elektryczną z zainstalowanych paneli solarnych.",
                        p4: "Dodatkowo na terenie posiadłości ulokowany jest basen o wymiarach 12 x 6 metrów, spora przestrzeń rekreacyjna oraz niewielki gaj oliwny.",
                    },
                    location: {
                        p1: "Casa „Di Dario” położony jest wśród pięknych winnic i gajów oliwnych, zaledwie 4 kilometry od urokliwego miasteczka Montecchio. Widoki na dolinę, okoliczne wzgórza i miasteczko zapierają dech w piersiach, a w oddali można dostrzec najwyższy szczyt Toskanii – Monte Amiata.",
                        p2: "Najbliżej położone miejscowości, stanowiące historyczną atrakcję to Orvieto, oddalone o 20 kilometrów oraz Todi - o 25 kilometrów. Dogodna lokalizacja sprawia, że odległość do Rzymu wynosi jedynie 110 kilometrów, natomiast do najbliższych stacji w Alviano i Orvieto dotrzemy pociągiem z Rzymu jedynie w godzinę i kwadrans.",
                        p3: "W promieniu jednej godziny jazdy od Casa „Di Dario” znajdują się urocze, pełne zabytków miasteczka jak Perugia, Montepulciano, Cortona, Narni, Amelia, Bagnoreggio, Spoleto i wiele innych.",
                    },
                    accomodation: {
                        p1: "Casa „Di Dario” oferuje możliwość zakwaterowania maksymalnie dla 8 osób w czterech sypialniach, z których trzy wyposażone są w duże łóżka małżeńskie, a ostatnia w dwa łóżka pojedyncze.",
                        p2: "Minimalny okres zakwaterowania obejmuje 1 tydzień. Dom przyjmuje gości od maja do października, a w pozostałe części roku rezerwacja możliwa jest po wcześniejszym ustaleniu szczegółów.",
                    },
                },
            },
            component3: {
                discover: "Odkryj posiadłość na zdjęciach",
                tabSelector: {
                    outside: "Na zewnątrz",
                    downstairs: "Parter",
                    upstairs: "Pierwsze piętro",
                    surroundings: "Okolica",
                },
            },
            contactInfoButton: {
                seeContactInfo: "Informacje kontaktowe",
                contactInfoCard: {
                    title: "Informacje kontaktowe",
                    phone: "Telefon: +48 603 110 010",
                    email: "{'E-mail: Dario@casadidario.it'}",
                    address:
                        "Adres: Via Volara 8, Montecchio (Prowincja Terni), Umbria, Włochy 05020",
                    CIN: "CIN: IT055018C2L6034847",
                },
            },
            bottomBar: {
                copyright: "Copyright © 2024 Casa Di Dario",
                downloadRules: "Pobierz regulamin",
                seeContactInfo: "Informacje kontaktowe",
                contactInfoCard: {
                    title: "Informacje kontaktowe",
                    phone: "Telefon: +48 603 110 010",
                    email: "{'E-mail: Dario@casadidario.it'}",
                    address:
                        "Adres: Via Volara 8, Montecchio (Prowincja Terni), Umbria, Włochy 05020",
                    CIN: "CIN: IT055018C2L6034847",
                },
            },
            privacyPolicy: {
                button: "Polityka prywatności",
                title: "POLITYKA PRYWATNOŚCI",
                p1: "Od 25 maja 2018r. obowiązuje Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016r dotyczące ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych tzw. RODO",
                p2: "Przepisy te nakładają na nas obowiązek poinformowania Państwa o fakcie przetwarzania Państwa danych osobowych przez naszą firmę.",
                list1: {
                    li1: "Administratorem Państwa danych jest Casa di Dario, Dariusz Węglicki, ul. Pocztowa 5H, 05-520 Konstancin-Jeziorna, Polska NIP: 5211549145, oraz Daweg Consulting sp. z o.o., ul. Koncertowa 11 lok. 3, 02-787 Warszawa, NIP: 5213276556,",
                    li2: "W celu ochrony Państwa danych administrator działa w oparciu o wewnętrzne procedury i zalecenia, zgodnie z odpowiednimi aktami prawnymi w zakresie ochrony osób, których dane dotyczą.",
                    li3: {
                        p1: "Państwa dane osobowe przetwarzane są w celu:",
                        li1: "dokonania rezerwacji (w tym zakresie wymagane są imię i nazwisko, adres e-mail, numer telefonu),",
                        li2: "umożliwienia udzielenia odpowiedzi na zapytania związane z realizacją usługi,",
                        li3: "realizacji transakcji,",
                        li4: "zawarcia lub wykonania umowy, której stroną jest osoba, której dane dotyczą, lub do podjęcia działań na żądanie osoby, której dane dotyczą, przed zawarciem umowy, przesyłania informacji handlowej za pomocą środków komunikacji elektronicznej, marketingu bezpośredniego własnych produktów lub usług Administratora,",
                        li5: "realizację wszystkich usług świadczonych na terenie Casa di Dario,",
                        li6: "dochodzenia roszczeń z tytułu prowadzonej działalności gospodarczej,",
                        li7: "wypełnienia obowiązku prawnego ciążącego na Administratorze,",
                        li8: "udokumentowanie wykonania usługi dla celów podatkowych,",
                        li9: "zapewnienia bezpieczeństwa,",
                        li10: "przesyłania ofert handlowych i marketingowych w przypadku, gdy gość wyraził zgodę na przetwarzanie danych w celach marketingowych.,",
                        li11: "podanie Państwa danych jest dobrowolne, ale jest niezbędne do dokonania rezerwacji usług, zameldowania w hotelu, realizacji usług świadczonych na terenie hotelu oraz udzielenia odpowiedzi na pytania dotyczące usług świadczonych przez Administratora.",
                    },
                },
                p3: "Niepodanie danych uniemożliwi dokonanie rezerwacji, zameldowanie w obiekcie oraz świadczenie dodatkowych usług oferowanych przez Casa di Dario.",
                p4: "Państwa dane osobowe mogą być przekazywane do firm z którymi współpracujemy",
                list2: {
                    li1: "kancelarii prawnych w celu ustalenia, dochodzenia lub ochrony przed roszczeniami,",
                    li2: "firm informatycznych dostarczającym oprogramowanie oraz świadczącym usługi informatyczne,",
                    li3: "firmy ochroniarskiej w celu zapewnienia bezpieczeństwa,",
                    li4: "Państwa dane będą przechowywane przez okres niezbędny do wykonania umowy, lub dochodzenia roszczeń z tytułu prowadzonej działalności , okres niezbędny wynikający z przepisów prawa a w przypadku danych osobowych przetwarzanych na podstawie zgody osoby, której dane dotyczą - do odwołania zgody.",
                },
                list3: {
                    li4: "Państwa dane nie będą przekazywane do państwa trzeciego",
                    li5: {
                        p1: "Informujemy, że mają Państwo prawo do:",
                        li1: "dostępu do swoich danych osobowych, ich sprostowania, usunięcia, ograniczenia przetwarzania i ich przenoszenia,",
                        li2: "wniesienia skargi do organu nadzorczego,",
                        li3: "wycofania zgody na przetwarzanie danych osobowych w dowolnym momencie. Wycofanie zgody nie wpływa na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej wycofaniem. Wycofanie zgody może zostać dokonane w takiej samej formie, w jakiej została udzielona zgoda,",
                        li4: "wniesienia sprzeciwu wobec przetwarzania Państwa danych gdy Administrator zamierza je przetwarzać w celach marketingowych lub wobec przekazywania Państwa danych osobowych innemu administratorowi danych,",
                        li5: "uzyskaniu informacji dotyczącej występowania Państwa danych w zbiorach Administratora, celu, zakresu i sposobu przetwarzania danych zawartych w takim zbiorze. Stanu od kiedy przetwarza się Państwa dane w zbiorze. Źródle pozyskania danych. Udostępniania Państwa danych, a w szczególności informacji o odbiorcach lub kategoriach odbiorców, którym dane te są udostępniane.",
                    },
                },
            },
            cookiesSheet: {
                title: "Twoja prywatność",
                p1: "Ten biznes decyduje o wykorzystaniu danych osobowych zbieranych na naszych mediach i w internecie. Możemy zbierać dane, które przekazujesz nam bezpośrednio, lub dane, które zbieramy automatycznie, w tym z plików cookie (takie jak informacje o urządzeniu lub adres IP).",
                p21: "Prosimy przeczytaj naszą ",
                p2pp: "Politykę Prywatności",
                p22: "aby dowiedzieć się o naszych praktykach dotyczących prywatności.",
                accept: "Akceptuj",
                decline: "Odrzuć",
            },
        },
    },
};

const customLightTheme = {
    dark: false,
    colors: {
        red: "#C45E3C",
        green: "#4B8483",
        white: "#FAF9F6",
        grey: "#555550",
    },
};

const i18n = createI18n({
    legacy: false, // Vuetify does not support the legacy mode of vue-i18n
    locale: "en",
    fallbackLocale: "pl",
    globalInjection: true,
    messages,
});

const vuetify = createVuetify({
    components,
    directives,
    locale: {
        adapter: createVueI18nAdapter({ i18n, useI18n }),
    },
    theme: {
        defaultTheme: "customLightTheme",
        themes: {
            customLightTheme,
        },
    },
});
const app = createApp(App);
app.use(router);
app.use(store);
app.use(vuetify);
app.use(i18n);
app.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyDy1_ZKQldfkARhdPw0lVg9OiJaGDHLtoo",
        // language: 'de',
    },
});
app.use(VueGtag, {
    bootstrap: false,
    config: { id: "G-WSKS9HZG62" },
    router,
});
app.use(VueCookies, { expires: "7d" });
app.mount("#app");
