<template>
    <v-expand-transition>
        <v-card
            max-width="400"
            prepend-icon="mdi-card-account-mail-outline"
            :title="$t('landingPage.contactInfoButton.contactInfoCard.title')"
            v-show="getTopContactDialogOpen"
            class="position-fixed bottom-0 mb-28 ml-4">
            <template v-slot:append>
                <v-btn
                    icon="mdi-close"
                    @click="toggleTopContactDialogOpen()"></v-btn>
            </template>
            <template v-slot:text>
                <p>
                    {{
                        $t(
                            "landingPage.contactInfoButton.contactInfoCard.phone"
                        )
                    }}
                </p>
                <p>
                    {{
                        $t(
                            "landingPage.contactInfoButton.contactInfoCard.email"
                        )
                    }}
                </p>
                <p>
                    {{
                        $t(
                            "landingPage.contactInfoButton.contactInfoCard.address"
                        )
                    }}
                </p>

                <p>
                    {{
                        $t("landingPage.contactInfoButton.contactInfoCard.CIN")
                    }}
                </p>
            </template>
        </v-card>
    </v-expand-transition>
</template>
<script>
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";

// Components
export default defineComponent({
    name: "TopBarContactDialog",

    components: {},
    methods: {
        ...mapActions("LandingPage", ["toggleTopContactDialogOpen"]),
    },
    computed: {
        ...mapGetters("LandingPage", {
            getTopContactDialogOpen: "getTopContactDialogOpen",
        }),
    },
});
</script>
<style scoped>
.mb-28 {
    margin-bottom: 112px;
}
</style>
